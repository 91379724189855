<template>
  <div class="summary-configuration">
    <div
      v-for="configuration in configurationPresenter(configurations)"
      :key="configuration.id"
    >
      <p class="summary-configuration__name">
        {{ getLocalizedName(configuration.name) }}:
      </p>
      <div class="summary-configuration__option">
        <Component
          :is="product.isEqual(option.product) ? 'span' : 'ProductLink'"
          v-for="option in getSortedOptions(configuration.options)"
          :key="option.id"
          :product="product.isEqual(option.product) ? null : option.product"
          :class="[
            { active: product.isEqual(option.product) },
            option.type,
            { outOfStock: !option.product.isActive() },
          ]"
          class="ga-product-options"
          :style="{ '--color-bg': option.parameters?.hex }"
        >
          {{ "color" !== option.type ? getLocalizedName(option.value) : null }}
        </Component>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";

const props = defineProps({
  product: { type: Product, required: true },
  configurations: { type: [Array, null], required: false },
});

const currentConfiguration = (configurations) => {
  return configurations.find(({ product }) => {
    return props.product.isEqual(product);
  });
};

const configurationPresenter = (configurations) => {
  const configurationPresenter = {};

  configurations.forEach((configuration) => {
    configuration.dimensions.forEach((dimension) => {
      if (!configurationPresenter.hasOwnProperty(dimension.id)) {
        configurationPresenter[dimension.id] = {
          id: dimension.id,
          name: dimension.name,
          options: {},
        };
      }

      if (
        !configurationPresenter[dimension.id].options.hasOwnProperty(
          dimension.option.id,
        )
      ) {
        const getOptionProduct = configurations.find((configuration) =>
          configuration.dimensions.every((configurationDimension) => {
            if (configurationDimension.id === dimension.id) {
              return configurationDimension.option.id === dimension.option.id;
            }

            return (
              currentConfiguration(configurations).dimensions.find(
                (currentDimension) =>
                  currentDimension.id === configurationDimension.id,
              ).option?.id === configurationDimension.option.id
            );
          }),
        )?.product;

        if (undefined !== getOptionProduct) {
          configurationPresenter[dimension.id].options[dimension.option.id] = {
            ...dimension.option,
            product: new Product(getOptionProduct),
          };
        }
      }
    });
  });

  return configurationPresenter;
};

const getSortedOptions = (options) => {
  const formatToNumber = (value) =>
    getLocalizedName(value).replace(/^\D*(\d+).*/, "$1");

  return Object.values(options).sort(
    (a, b) => formatToNumber(a.value) - formatToNumber(b.value),
  );
};
</script>
